/* Softwares.css */

.softwares-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    padding: 20px;
   
    display: flex;
  }
  
  .software-item {
    display: flex;
    align-items: center;
    width: 200px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .software-item:hover {
    transform: scale(1.05);
  }
  
  .software-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .software-name {
    font-size: 1.2em;
    color: #333;
  }
  