/* Projects.css */

/* Modal styling */
.project-modal {
    background: #ffffff;
    padding: 30px;
    border-radius: 16px;
    max-width: 75vw;
    margin: 0 auto;
    position: relative;
    outline: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, #0070f3, #00c6ff);
  }
  
  .project-modal-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    text-align: left;
 
  }
  
  .modal-content >*{
    color:white
  }

  .modal-content h1 {
    margin-bottom: 20px;
  }
  
  .modal-img {
    max-width: 100%;
    width: 75%;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  .close-modal-button {
    background: linear-gradient(to right, #0070f3, #00c6ff);
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    font-weight: bold;
  }

  .meta-image, .modal-img{
    max-width: 100%;
    width:80%;
  }
  
  .close-modal-button:hover {
    background: linear-gradient(to right, #00c6ff, #0070f3);
    transform: scale(1.05);
  }
  
.arrow:hover{
  transform: scale(1.1);
  cursor: pointer;
}

  @media (max-width: 768px) {
    .project-modal {
      width: 90%;
    }
  }
  